import styled from "styled-components"
import color from "../../style/color"

export const Breadcrumb = styled.div`
  display: flex;
  width: 100%;
  padding: 30px 5% 0;
  ${props => props.noPadding && `padding-left: 0;`}
  margin: 0 auto;
  align-items: center;
  ${props => props.blog && "width: 92%; max-width: 1200px; padding: 30px 0 0;"}
  ${props =>
    props.greyBackground && `background: ${color.background.grey};`}
  & span {
    margin: 0 2rem;
    &:last-of-type {
      display: none;
    }
  }
  @media (max-width: 1023px) {
    display: none;
  }
`

export const Link = styled.a`
  font-size: 1.6rem !important;
  line-height: 2.6rem !important;
  font-weight: 300;

  &:last-of-type {
    &:hover,
    &:active {
      text-decoration: none;
      cursor: default;
    }
  }
`

export const Icon = styled.img`
  margin-right: 10px;
  margin-left: 12px;
  height: 26px;
  width: 11px;
  transform: rotate(-90deg);
  &:last-of-type {
    display: none;
  }
`
